<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="circularLoading">
                <b-row class="mt-3">
                  <b-col lg="12" sm="12">
                  </b-col>
                  <b-col lg="12" sm="12">
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="trainingData.office_id">
                      {{ }}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row class="m-3">
                  <b-col lg="12" sm="12" v-if="$i18n.locale === 'bn'">
                    {{ $t('elearning_tim.sheet_1') }} {{ getOfficeName(item.office_id) }} {{ $t('elearning_tim.sheet_2') }}
                    {{  }} {{ getDistrictName(circularData.district_id) }} {{ 'জেলার' }} {{ getUpazilaName(circularData.upazila_id) }} {{ 'উপজেলায়' }} {{ circularData.training_start_date | dateFormat }} {{ 'হতে' }} {{ circularData.training_end_date | dateFormat }} {{ 'তারিখ' }} {{ getTrainingTitle(item.training_title_id) }} {{ $t('elearning_tim.sheet_3') }}
                    {{ date | dateFormat }}  {{ 'তারিখে' }} {{ scheduleData.start_time | time12FormateTraining  }} {{ 'টা হতে ' }} {{ scheduleData.end_time | time12FormateTraining  }} {{ 'টা পর্যন্ত' }} {{ 'টা হতে প্রশিক্ষক হিসাবে' }} {{ ($i18n.locale === 'bn') ? scheduleData.topic_bn : scheduleData.topic_en }} {{ $t('elearning_tim.sheet_4') }} {{ $n(trainingData.honour_amount) + '/-' }} {{ 'টাকা মাত্র এর' }} {{ $n(trainingData.vat_tax) + '%' }} {{ 'উৎসে কর ' }} {{ $n(vat_tax_amount) + '/-' }} {{ $t('elearning_tim.sheet_5') }}
                    {{ $n(payment_amount) + '/-' }} {{ $t('elearning_tim.sheet_6') }}
                  </b-col>
                  <b-col lg="12" sm="12" v-if="$i18n.locale === 'en'">
                    {{ 'Bangladesh Applied Nutrition Research and Training Institute (BARTAN) under the management of' }} {{ getOfficeName(item.office_id) }} {{ 'office at' }} {{ getUpazilaName(circularData.upazila_id) }} {{ 'upazila of' }} {{ getDistrictName(circularData.district_id) }} {{ 'district in the training course on food based nutrition (applied nutrition) held from' }} {{ circularData.training_start_date | dateFormat }} {{ 'to' }} {{ circularData.training_end_date | dateFormat }} {{ 'on' }} {{ date | dateFormat }} {{ 'as a trainer from' }} {{ scheduleData.start_time | time12FormateTraining  }} {{ 'to' }} {{ scheduleData.end_time | time12FormateTraining  }} {{ 'after giving statement on the subject' }} {{ getTrainingTitle(item.training_title_id) }} {{ $n(trainingData.honour_amount) + '/-' }} {{ 'taka only for' }} {{ $n(trainingData.vat_tax) + '%' }} {{ 'at source tax ' }} {{ $n(vat_tax_amount) + '/-' }} {{ 'taka only after deducting' }} {{ $n(payment_amount) + '/-' }} {{ 'tk only I received.' }}
                  </b-col>
                  <b-col lg="12" sm="12" class="mt-3 mb-3">
                    <table hover small caption-top>
                      <tbody>
                        <tr>
                          <td style="width: 10%" class="text-left" >{{ $t('elearning_tim.trainer') }} {{ $t('globalTrans.signature') }}</td>
                          <td class="text-center" style="width: 3%">:</td>
                          <td style="width: 32%" class="text-left"></td>
                        </tr>
                        <tr>
                          <td style="width: 10%" class="text-left" >{{ $t('elearning_tim.trainer') }} {{ $t('globalTrans.name') }}</td>
                          <td class="text-center" style="width: 3%">:</td>
                          <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? trainingData.personal.name_bn : trainingData.personal.name }}</td>
                        </tr>
                        <tr>
                          <td style="width: 10%" class="text-left" >{{ $t('globalTrans.designation') }}</td>
                          <td class="text-center" style="width: 3%">:</td>
                          <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? trainingData.personal.designation_bn : trainingData.personal.designation }}</td>
                        </tr>
                        <tr>
                          <td style="width: 10%" class="text-left" >{{ $t('elearning_tim.institutions') }} {{ $t('globalTrans.name') }}</td>
                          <td class="text-center" style="width: 3%">:</td>
                          <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? trainingData.personal.other_org_bn : trainingData.personal.other_org }}</td>
                        </tr>
                        <tr>
                          <td style="width: 10%"  class="text-left">{{ $t('elearning_tim.trainer') }} {{ $t('globalTrans.mobile') }}</td>
                          <td style="width: 3%" class="text-center">:</td>
                          <td style="width: 32%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(trainingData.personal.mobile, { useGrouping: false }) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
               </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularDetails, scheduleDetails } from '../../api/routes'
import ExportPdf from './export_pdf_sheet'
export default {
  components: {
      ListReportHead
  },
  name: 'FormLayout',
  props: ['item', 'date'],
  created () {
    this.getCircularData()
    this.getScheduleData()
    this.trainingData = this.item
    this.vat_tax_amount = this.trainingData.honour_amount * this.trainingData.vat_tax / 100
    this.payment_amount = this.trainingData.honour_amount - this.vat_tax_amount
  },
  data () {
    return {
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      circularLoading: false,
      trainingData: [],
      circularData: [],
      scheduleData: [],
      vat_tax_amount: '',
      payment_amount: '',
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getDistrictName (id) {
        const office = this.$store.state.commonObj.districtList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getUpazilaName (id) {
        const office = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getTrainingTitle (id) {
        const office = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    async getCircularData () {
      if (this.item.fiscal_year_id && this.item.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.item.fiscal_year_id,
          training_type_id: this.item.training_type_id,
          training_category_id: this.item.training_category_id,
          training_title_id: this.item.training_title_id,
          circular_memo_no: this.item.circular_memo_no,
          office_id: this.item.office_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularDetails + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.circularData = result.data
        }
        this.circularLoading = false
      }
    },
    async getScheduleData () {
      if (this.item.fiscal_year_id && this.item.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.item.fiscal_year_id,
          training_type_id: this.item.training_type_id,
          training_category_id: this.item.training_category_id,
          training_title_id: this.item.training_title_id,
          circular_memo_no: this.item.circular_memo_no,
          training_date: this.date,
          trainer_id: this.item.personal.id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, scheduleDetails, serchData)
        if (!result.success) {
          this.scheduleData = []
        } else {
          this.scheduleData = result.data
        }
        this.circularLoading = false
      }
    },
    pdfExportDetails () {
      const reportTitle = this.$t('elearning_tim.other_honorarium_sheet')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.scheduleData, this.circularData, this.trainingData)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
